<div class="btn-group">
    @for (btn of buttons; track btn) {
        <button
            class="btn {{btnClass}}" [translate]="btn.label"
            [attr.data-cy]="btn.label"
            [class.btn-primary]="selected === btn.value"
            [class.btn-secondary]="selected !== btn.value"
            (click)="valueChange.emit(btn.value)"
        ></button>
    }
</div>
